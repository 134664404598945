import React from 'react'
import styled, { css } from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Share from 'components/elements/Share'
import Image2 from 'components/shared/Image2'
import { ClientCaseBlockQuote } from 'components/flex/ClientCase'

// Context
import { BlogContext } from 'templates/post'

interface BlockProps {
  type?: null | 'top' | 'bottom'
  highlighted?: boolean
  index?: number
}

const Content = styled.div`
  position: relative;
  z-index: 1;
`

const Block = styled.div<BlockProps>`
  background-color: ${({ theme, highlighted }) =>
    highlighted ? theme.color.face.border : theme.color.face.light};
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  position: relative;

  ${(props) =>
    props.type === 'top' &&
    css`
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 25px;
    `};

  ${(props) =>
    (props.type === 'bottom' || props.index === 0) &&
    css`
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
    `};
`

const BlockEnd = styled.div`
  background-color: ${({ theme }) => theme.color.face.light};
  position: absolute;
  top: calc(100% - 25px);
  height: 50px;
  left: 0;
  width: 100%;
  z-index: 1;
`

const BlockIcon = styled(Image2)`
  @media (min-width: 992px) {
    height: 65px;
  }

  @media (max-width: 991px) {
    height: 50px;
  }
`

const BlockSharing = styled.div`
  @media (max-width: 991.98px) {
    background-color: ${({ theme }) => theme.color.face.light};
  }
`

const BlockSharingTitle = styled.div`
  font-size: ${(props) => props.theme.font.size.sm};
  color: ${(props) => props.theme.color.text.contrast};
`

const BlockDescription = styled(ParseContent)`
  color: ${(props) => props.theme.color.text.purple};

  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    margin: 0.75rem 0 0.25rem;
    font-size: ${(props) => props.theme.font.size.xm} !important;
    font-weight: ${(props) => props.theme.font.weight.xm} !important;
  }

  & ul > li {
    margin-bottom: 0.25rem;
  }

  & ul:last-child {
    margin-bottom: 0;
  }

  & a {
    text-decoration: underline;
  }

  & .button a {
    text-decoration: none;
  }
`

const BlockQuoteImage = styled(Image2)`
  & img {
    object-fit: contain !important;
  }

  @media (min-width: 576px) {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 575.98px) {
    max-width: 150px;
    margin: 0 auto;
  }
`

const BlockQuote = styled(ClientCaseBlockQuote)`
  position: absolute;
  opacity: 1;
  z-index: 0;

  & path {
    fill: ${({ theme }) => theme.color.face.border};
  }

  @media (min-width: 576px) {
    width: 150px;
    height: 125px;
    left: -25px;
    top: -30px;
  }

  @media (max-width: 575px) {
    width: 100;
    height: 60px;
    left: -5px;
    top: -35px;
  }
`

const BlockQuoteDescription = styled(ParseContent)`
  color: ${(props) => props.theme.color.text.purple};
  position: relative;
  z-index: 1;

  & ul > li {
    margin-bottom: 0;
  }
`

const End = styled.div`
  background-color: ${({ theme }) => theme.color.face.background};
  height: 225px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
`

interface BlogDescriptionProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_BlogDescription
  location: any
}

const BlogDescription: React.FC<BlogDescriptionProps> = ({
  fields,
  location,
}) => {
  const blogContext = React.useContext(BlogContext)

  return (
    <section className="position-relative pb-4 pb-lg-5">
      <Content className="pb-3">
        {fields.descriptions?.map((edge, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            {edge?.typedescription === 'description' && (
              <div className="container">
                <div className="row">
                  <div className="col-lg-1">
                    {index === 0 && (
                      <BlockSharing className="pt-3 pb-3 pb-lg-0 pt-lg-5 d-lg-block d-flex align-items-center">
                        <BlockSharingTitle className="text-center pr-lg-0 pr-4 pl-lg-0 pl-4">
                          share
                        </BlockSharingTitle>
                        <div className="mt-3 d-lg-block d-none">
                          <Share
                            location={location.href}
                            title={blogContext.title}
                            type="vertical"
                          />
                        </div>
                        <div className="d-lg-none d-block">
                          <Share
                            location={location.href}
                            title={blogContext.title}
                            type="horizontal"
                            className="d-flex"
                          />
                        </div>
                      </BlockSharing>
                    )}
                  </div>
                  <div className="col-lg-10">
                    <Block
                      className={`px-lg-5${index === 0 ? ' pt-3 pt-lg-5' : ''}`}
                      index={index}
                    >
                      <BlockDescription
                        className="px-4 px-lg-5 py-4"
                        content={edge?.description}
                      />

                      <BlockEnd />
                    </Block>
                  </div>
                </div>
              </div>
            )}

            {edge?.typedescription === 'meticoon' && (
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-10">
                    <Block className="px-lg-5" index={index}>
                      <div className="px-4 px-lg-5 py-4">
                        <BlockIcon image={edge.icon} />
                        <BlockDescription content={edge?.description} />
                      </div>

                      <BlockEnd />
                    </Block>
                  </div>
                </div>
              </div>
            )}

            {edge?.typedescription === 'quote' && (
              <div className="color-background-background">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-10">
                      <Block className="py-4" type="top" />
                      <div className="py-5">
                        <div className="row mb-sm-0 mb-4 justify-content-center">
                          <div className="col-sm-4 d-flex align-items-center">
                            <BlockQuoteImage image={edge.image} />
                          </div>
                          <div className="col-sm-6 mt-4 mt-sm-0 position-relative d-flex align-items-center">
                            <BlockQuote />
                            <BlockQuoteDescription content={edge.description} />
                          </div>
                        </div>
                      </div>
                      <Block className="py-4" type="bottom">
                        <BlockEnd />
                      </Block>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {edge?.typedescription === 'highlighted' && (
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-10">
                    <Block
                      className="px-lg-5 py-4 py-lg-5"
                      type={
                        index + 1 === fields?.descriptions?.length
                          ? 'top'
                          : null
                      }
                      highlighted
                      index={index}
                    >
                      <BlockDescription
                        className="px-4 px-lg-5 py-4"
                        content={edge?.description}
                      />
                    </Block>
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      </Content>

      <End />
    </section>
  )
}

export default BlogDescription
